.home_page_main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em;
    gap: 5em;
    background: #000000;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
}

.home_page_top {
    margin-top: 10em;
    display: flex;
    width: 67%;
    justify-content: space-between;
}

.join_tournament_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    background: linear-gradient(223.27deg, #0026FF 0.32%, #FF9AFB 77.33%);
    border-radius: 100px;
    color: white;
    border: 0px;
}

.tournaments-table-container {
    color: white !important;
    background-color: #0D0D0D;
    /* border: 1px solid #272727; */
}

.tournaments-table-container table {
    padding: 1em;
}

.tournaments-table-container table thead {
    padding: 1em;
    opacity: 0.6;
    border-radius: 30px 30px 0px 0px;
    border: 1px solid #272727;
}

.tournaments-table-container table tbody tr {
    border: 1px solid #272727 !important;
    padding: 1em 3em;
}

.tournaments-table-container table tbody tr td {
    margin-top: 0.5em;
    padding: 0.5em 3em;
}

.tournaments-table-container table thead tr td {
    padding: 1em 2.5em;
}

.match_status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #211616 !important;
    border-radius: 100px;
    margin-top: 1em !important;
    margin-bottom: 1em;
    font-size: 11px;
    color: #A55858;
}

.view_btn {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    color: #1E3FFF;
    cursor: pointer;
}

.view_btn span {
    margin-right: 0.5em;
}

.success_body_p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.01em;
    color: rgba(255, 255, 255, 0.5);
}

.viewMatchDetails {
    background: #141414;
    border: 1px solid #272727;
    border-radius: 15px;
    padding: 2em;
    margin: 1em;
}

.viewMatchDetailHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    opacity: 0.4;
}

.joinMatchBtn {
    background: linear-gradient(95.56deg, #1E3FFF -12.14%, #C1A4FF 102.74%);
    border-radius: 1000px;
}