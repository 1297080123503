@fontface {
  font-family: "Inter";
  src: url("https://fonts.googleapis.com/css?family=Inter");
}

.setting-container {
  margin-top: calc(60px + 1.85rem);
  position: absolute;
  width: 400px;
}

.setting_main_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  text-align: center;
  letter-spacing: -0.01em;
  color: #26292d;
  background: #ffffff;
  border-bottom: 1px solid #e6e8ec;
}

.toggles_container {
  display: flex;
  flex-direction: column;
}

.toggle_setting_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em;
}

.toggle_setting_left_container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.toggle_setting_right_container {
  display: flex;
  flex-direction: row;
}

.setting_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #26292d;
}

.setting_desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(38, 41, 45, 0.6);
}

.toggle_setting_right_container {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: -0.01em;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 0.6em;
}

.toggle_setting_right_container > span {
  opacity: 0.4;
}

/*  toggler */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f1a624;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f1a624;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
