.login_main_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.metamask-container {
    width: 300px;
    height: 300px;
}

.metamask-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}