.user_profile_page {
    height: 100vh;
    background-color: black;
    padding: 5em 14em;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    color: white;
    gap: 2em;
}

.user_profile_page h3 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
}

.user_profile_page .user-details {
    background: #0D0D0D;
    border: 1px solid #272727;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 3em;
    gap: 2em;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
}

.user_profile {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
}

.user_profile .img-container {
    width: 100px;
    height: 100px;
}

.user_profile .img-container img {
    width: 100%;
    height: 100%;
}

.profile_img_upload_size {
    display: flex;
    flex-direction: column;
}

.rec_size {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: #FFFFFF;

    opacity: 0.1;

}

.input_fields {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.input_fields .field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #272727;
    padding-bottom: 1em;
}

.input_fields .field input {
    background: #0D0D0D;
    border: 0px;
    outline: none;
    color: white;
}

.action-color {
    color: #006EFF;
}