.my_tournaments_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3em;
}

.tabs {
    display: flex;
    justify-content: center;
    /* gap: 3em; */
}

.tab {
    padding: 1em 4.5em 1em 4.5em;
    border: 1px solid gray;
    border-radius: 60px;
    cursor: pointer;
}


.selected-tab {
    background-color: blue;
    color: white;
}

.content {
    padding: 2em 1em;
}