@fontface {
  font-family: "Inter";
  src: url("https://fonts.googleapis.com/css?family=Inter");
}

.tutorial_main_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  text-align: center;
  letter-spacing: -0.01em;
  color: #26292d;
  background: #ffffff;
  border-bottom: 1px solid #e6e8ec;
}

.tutorial_main_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #26292d;
  justify-content: center;
  padding: 1em;
  background: #ffffff;
  border-bottom: 1px solid #e6e8ec;
}

.accordion_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #26292d;
}

.accordion_body_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #26292d;
}

.accordion_body_video {
  margin-top: 1em;
}
