.create-tournament {
    height: 80%;
    background-color: black;
    color: white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    overflow: scroll;
}

.create-tournament form {
    margin-top: 1em;
}

.create-tournament div {
    margin-top: 1em;
}

.new_tournament_heading {
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 23px 25px;
    gap: 10px;
    background: #0D0D0D;
    border-bottom: 1px solid #272727;
}

.create-tournament input {
    color: white;
    background: #141414;
    border: 1px solid #272727;
    border-radius: 10px;
    width: 500px;
}

.create-tournament input ::placeholder{
    color: white;
}

.create-tournament select {
    color: white;
    background: #141414;
    border: 1px solid #272727;
    border-radius: 10px;
    width: 500px;
}

.ReactModal__Overlay {
    background-color: rgb(13 13 13 / 75%) !important;
}