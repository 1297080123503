ul {
  padding: 0;
  margin: 0;
}

.casino {
  margin: auto;
  width: 400px;
}

.casino__history,
.casino__referrals,
.casino__apply {
  position: absolute;
  top: 0;
  z-index: -99;
  height: 100vh;
  width: 400px;
  background-image: url("../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: calc(60px + 2.5rem); /* 60px is the navbar */
}

.casino__history ul,
.casino__referrals ul {
  width: 90%;
  margin-top: 2rem;
}

.casino__history li,
.casino__referrals li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin-bottom: 1rem;
  font-weight: 700;
}

.casino__apply input {
  background-color: #e41717;
  border: 2px solid rgba(0, 0, 0, 0.4);
  height: 48px;
  border-radius: 50rem;
  color: white;
}

.casino__footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #8d080a;
  padding: 0.6rem;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.casino__footer button,
.casino__footer a {
  width: 48%;
  background-color: #e41717;
  color: white;
  font-weight: 700;
  font-size: 0.85rem;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 40px;
}

@media screen and (max-width: 400px) {
  #custom-warning,
  #hamburgerToggler,
  #tokenToggler,
  .setting-container,
  .casino__navbar,
  .casino__background,
  .casino__main,
  .casino__history,
  .casino__referrals,
  .casino__apply,
  .casino {
    width: 100%;
  }

  .casino__main .total-earned {
    margin-bottom: 1rem;
  }

  .casino__machine {
    margin-bottom: 1.5rem;
  }

  .winning {
    margin-top: -76px !important;
  }
}
