.casino__background {
  top: 0;
  position: fixed;
  z-index: -999;
  height: 100vh;
  width: 400px;
  background-image: url("../../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.casino__main {
  z-index: -9;
  position: absolute;
  margin-top: 2rem;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.casino__main .total-earned {
  color: white;
  font-weight: 700;
  margin-bottom: 2.5rem;
}

.casino__main .casino__buttons {
  text-align: center;
}

.casino__main .casino__buttons button {
  cursor: pointer;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: white;
  border-radius: 50rem;
  padding: 0.7rem;
  width: 70%;
  height: 60px;
  border: 3px solid white;
  font-weight: 700;
  font-size: 1.2em;
  background: radial-gradient(
    61.76% 223.89% at 2.79% -4.66%,
    #6c0e04 0%,
    #f12626 12.92%,
    #cf2928 28.79%,
    #860b0b 45.2%,
    #8c0b0b 62.14%,
    #cb2827 79.93%,
    #a71e22 100%
  );
}

.gas-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.gas-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.gas-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.gas-switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.mt-below-navbar {
  margin-top: calc(60px + 0.25rem);
}

@media screen and (max-width: 400px) {
  #custom-warning,
  .casino__background,
  .casino__main,
  .casino__history,
  .casino__referrals,
  .casino__apply,
  .casino {
    width: 100%;
  }
}
